import React from 'react'

const TermsConditions = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2113_17114)">
                <path
                    d="M0.769206 1.33333C0.400539 1.33333 0.102539 1.03467 0.102539 0.666667C0.102539 0.298667 0.400539 0 0.769206 0H10.6532C11.5625 0 12.3892 0.376 12.9879 0.981333C13.5872 1.58667 13.9585 2.41933 13.9585 3.33333V11.4307C13.7672 11.3673 13.5632 11.3333 13.3512 11.3333H12.6252V3.33333C12.6252 2.78067 12.4032 2.28 12.0452 1.91867C11.6879 1.55733 11.1952 1.33333 10.6532 1.33333H0.769206ZM13.9585 15.236V15.3333C13.9585 15.7013 13.6605 16 13.2919 16H5.37454C4.46521 16 3.63854 15.624 3.03921 15.0187C2.44054 14.414 2.06854 13.5807 2.06854 12.6667C2.06854 12.2987 2.36721 12 2.73521 12C3.10387 12 3.40187 12.2987 3.40187 12.6667C3.40187 13.2193 3.62387 13.72 3.98187 14.0813C4.33921 14.4427 4.83254 14.6667 5.37454 14.6667H6.90587C6.54254 15.0753 6.01654 15.3333 5.43387 15.3333H13.3512C13.5632 15.3333 13.7672 15.2993 13.9585 15.236Z"
                    fill="white"
                />
                <path
                    d="M0.52053 1.37724C0.162529 1.29857 -0.063471 0.943901 0.0158624 0.585902C0.0945292 0.227902 0.449197 0.00190251 0.807197 0.0805691C1.55653 0.248569 2.21187 0.671902 2.67453 1.2539C3.1292 1.82523 3.40187 2.54923 3.40187 3.33323V12.6666C3.40187 13.0346 3.10387 13.3332 2.7352 13.3332C2.3672 13.3332 2.06853 13.0346 2.06853 12.6666V3.33323C2.06853 2.8599 1.9052 2.4239 1.63253 2.0819C1.35386 1.73057 0.963864 1.47657 0.52053 1.37724Z"
                    fill="white"
                />
                <path
                    d="M5.43359 10.6667H13.3509C14.0789 10.6667 14.7409 10.9674 15.2203 11.4521C15.6996 11.9361 15.9969 12.6027 15.9969 13.3334C15.9969 14.0641 15.6996 14.7307 15.2203 15.2147C14.7409 15.6994 14.0789 16.0001 13.3509 16.0001H5.43359V14.6667C5.79426 14.6667 6.12226 14.5174 6.36026 14.2774C6.59826 14.0367 6.74626 13.7027 6.74626 13.3334C6.74626 12.9641 6.59826 12.6301 6.36026 12.3894C6.12226 12.1494 5.79426 12.0001 5.43359 12.0001V10.6667ZM13.3509 12.0001H7.72159C7.94893 12.3934 8.07959 12.8494 8.07959 13.3334C8.07959 13.8174 7.94893 14.2734 7.72159 14.6667H13.3509C13.7116 14.6667 14.0396 14.5174 14.2776 14.2774C14.5156 14.0367 14.6636 13.7027 14.6636 13.3334C14.6636 12.9641 14.5156 12.6301 14.2776 12.3894C14.0396 12.1494 13.7116 12.0001 13.3509 12.0001Z"
                    fill="white"
                />
                <path
                    d="M5.37468 4.00008C5.00668 4.00008 4.70801 3.70141 4.70801 3.33341C4.70801 2.96541 5.00668 2.66675 5.37468 2.66675H10.6534C11.0214 2.66675 11.32 2.96541 11.32 3.33341C11.32 3.70141 11.0214 4.00008 10.6534 4.00008H5.37468Z"
                    fill="white"
                />
                <path
                    d="M5.37467 6.66659C5.00667 6.66659 4.70801 6.36859 4.70801 5.99992C4.70801 5.63192 5.00667 5.33325 5.37467 5.33325H6.69401C7.06267 5.33325 7.36134 5.63192 7.36134 5.99992C7.36134 6.36859 7.06267 6.66659 6.69401 6.66659H5.37467Z"
                    fill="white"
                />
                <path
                    d="M5.37467 9.33333C5.00667 9.33333 4.70801 9.03533 4.70801 8.66667C4.70801 8.29867 5.00667 8 5.37467 8H8.01401C8.38201 8 8.68067 8.29867 8.68067 8.66667C8.68067 9.03533 8.38201 9.33333 8.01401 9.33333H5.37467Z"
                    fill="white"
                />
                <path
                    d="M9.33269 6.66659C8.96468 6.66659 8.66602 6.36859 8.66602 5.99992C8.66602 5.63192 8.96468 5.33325 9.33269 5.33325H10.6527C11.0207 5.33325 11.3194 5.63192 11.3194 5.99992C11.3194 6.36859 11.0207 6.66659 10.6527 6.66659H9.33269Z"
                    fill="white"
                />
                <path
                    d="M10.6266 9.33333C10.2586 9.33333 9.95996 9.03533 9.95996 8.66667C9.95996 8.29867 10.2586 8 10.6266 8H10.6533C11.0213 8 11.32 8.29867 11.32 8.66667C11.32 9.03533 11.0213 9.33333 10.6533 9.33333H10.6266Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_2113_17114">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default TermsConditions
