import { getFetcher } from '@/utils/auth'
import useSWR from 'swr'

const url = `accounting-api/wallet`

export const useLoadUserWallet = ({ token, userName }) => {
    const { data, error, isValidating, mutate } = useSWR(
        token && url && userName ? [url, token] : null,
        getFetcher
    )

    const userWalletNotLoadedYet = !data

    const loading = !error && isValidating

    const wallet = data?.data || {}

    return { wallet, userWalletNotLoadedYet, loading, mutate }
}
