import {
    INITIALIZE_SEARCH_PAGE,
    UPDATE_FETCH_CONTENT_STATUS,
    GET_SEARCH_RESULT,
    UPDATE_FETCH_SEARCH_STATUS,
    UPDATE_SEARCH_TERM,
    GET_MORE_SEARCH_RESULT,
    UPDATE_FILTER,
    UPDATE_FOCUS,
} from '@/store/types/search'

export const initializeSearchPage = (payload) => ({
    type: INITIALIZE_SEARCH_PAGE,
    payload,
})

export const updateFetchContentStatus = (payload) => ({
    type: UPDATE_FETCH_CONTENT_STATUS,
    payload,
})

export const updateSearchTerm = (payload) => ({
    type: UPDATE_SEARCH_TERM,
    payload,
})

export const getSearchResult = (payload) => ({
    type: GET_SEARCH_RESULT,
    payload,
})

export const updateFetchSearchStatus = (payload) => ({
    type: UPDATE_FETCH_SEARCH_STATUS,
    payload,
})

export const getMoreSearchResults = (payload) => ({
    type: GET_MORE_SEARCH_RESULT,
    payload,
})

export const updateFilter = (payload) => ({
    type: UPDATE_FILTER,
    payload,
})

export const updateFocus = (payload) => ({
    type: UPDATE_FOCUS,
    payload,
})
