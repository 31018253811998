import { css } from 'styled-components'

export const RowFlex = css`
    display: flex;
    flex-direction: row;
`

export const ColumnFlex = css`
    display: flex;
    flex-direction: column;
`

export const FlexJustifyContentSpaceBetween = css`
    justify-content: space-between;
`

export const FlexAlignItemsCenter = css`
    align-items: center;
`

export const FlexCentered = css`
    display: flex;
    justify-content: center;
    align-items: center;
`
