import { RowFlex } from '@/themes/genericStyles'
import styled from 'styled-components'

export const Header = styled.div`
    ${RowFlex}
    padding: 15px;
    position: fixed;
    top: 0;
    // width: ${(props) => (props.fullScreen ? '100%' : '80%')};
    background-color: rgba(20, 20, 20, 0.8);
    align-items: center;
    z-index: 999;
    justify-content: space-between;
    align-self: center;
    // @media (max-width: 990px) {
    //     width: 100%;
    // }
    width: -webkit-fill-available;
    width: -moz-available;
`
export const BackButtonContainer = styled.div`
     {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px 0px 0px 16px;
        gap: 10px;
    }
`
export const BackButton = styled.img`
    height: 16px;
    margin-right: 10px;
    cursor: pointer;
`

export const PageHeaderImage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 0px 16px;
    gap: 10px;
    @media (min-width: 991px) {
        display: ${(props) => (props.showHeaderImage ? 'flex' : 'none')};
    }
`
export const UserDetailContainer = styled.div`
     {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        // padding: 8px;
        // gap: 16px;
        @media (max-width: 990px) {
            display: none;
        }
    }
`

export const UserName = styled.div`
     {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */

        color: #ffffff;
    }
`

export const SettingContainer = styled.div`
     {
        display: ${(props) => (props.showSettings ? 'flex' : 'none')};
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        // padding: 8px;
        // gap: 16px;
        @media (min-width: 991px) {
            display: none;
        }
    }
`
