import { ColumnFlex, RowFlex } from '@/themes/genericStyles'
import styled from 'styled-components'

export const Container = styled.div`
    ${ColumnFlex}
    // overflow-y: auto;
    // overflow-x: hidden;
    width: 100%;
    z-index: 9;
    height: ${(props) =>
        props.isFullScreenPlayer === true ? `100%` : 'unset'};
    background: #141414;
`
export const PageHeader = styled.div`
    ${RowFlex}
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding: 16px;
    position: relative;
    @media (min-width: 991px) {
        display: none;
    }
    z-index: 9;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`
export const PageHeaderImage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    img {
        width: 70px;
        height: 20px;
    }
`

export const PageHeaderLoginButton = styled.div`
     {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        height: 36px;
        padding: 0px 12px;
        border-radius: 36px;
        background: #fad810;
    }
`

export const PageHeaderLoginText = styled.div`
     {
        width: 52px;
        height: 14px;
        cursor: pointer;

        font-size: 12px;
        font-weight: 600;
        line-height: 17.5px;
        letter-spacing: 0.1px;

        display: flex;
        justify-content: center;
        align-items: center;
    }
`

export const PageHeaderVisitButtonContainer = styled.div`
     {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        cursor: pointer;

        // width: 98.67px;
        // height: 32px;

        /* Inside auto layout */

        flex: none;
        order: 3;
        // flex-grow: 1;
    }
`
export const PageHeaderVisitButton = styled.div`
     {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 9px;
        gap: 8px;

        // width: 98.67px;
        // height: 32px;

        /* Candle Light/500

#FAD810
Usage: Primary Color
*/
        background: #fad810;
        border-radius: 4px;

        /* Inside auto layout */

        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 1;
    }
`

export const PageHeaderVisitText = styled.div`
     {
        // width: 75px;
        // height: 14px;

        /* Button/Bold/Uppercase */

        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */

        letter-spacing: 0.2em;
        text-transform: uppercase;

        /* Black/900 */

        color: #000000;

        /* Inside auto layout */

        flex: none;
        order: 1;
        flex-grow: 0;
    }
`

export const PageNavigationContainer = styled.div`
    position: absolute;
    top: 20px;
    right: 15px;
    z-index: 9999;
`
export const PageNavigation = styled.ul`
    position: absolute;
    right: 0;
    top: 120%;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #1f1f1f;
    color: #ffffff;
`

export const PageNavigationItem = styled.li`
    cursor: pointer;
    padding: 8px 15px;
    font-size: 14px;
    white-space: nowrap;
    border-bottom: 1px solid #181818;
`

export const HeaderContainer = styled.div`
    ${RowFlex}
    position: fixed;
    height: 56px;
    @media (min-width: 992px) {
        width: 698px;
    }
    width: 100%;
    z-index: 4;
    background: ${(props) => (props.headerName === true ? `#000000` : 'none')};
`
export const Header = styled.div`
    ${RowFlex}
    padding: 10px;
    width: 100%;
    @media (min-width: 992px) {
        width: 700px;
    }
    background: #000000;
    z-index: 2;
`

export const HeaderRight = styled.div`
    ${RowFlex}
    justify-content: flex-start;
    width: 80%;
`
export const HeaderLeft = styled.div`
    ${RowFlex}
    justify-content: flex-end;
    width: 20%;
`

export const HeaderText = styled.div`
    ${ColumnFlex}
    padding: 2.44%;
    position: relative;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    color: #FFFFFF;
    }
`
export const BackButton = styled.img`
    height: 24px;
    width: 24px;
    margin-right: 1vw;
    cursor: pointer;
`

export const SearchImage = styled.img`
    height: 18px;
    width: 18px;
    margin-right: 3vw;
    cursor: pointer;
`

export const ShareImage = styled.img`
    height: 18px;
    width: 18px;
    cursor: pointer;
`
export const BottomDiv = styled.div`
    margin-bottom: 50%;
    @media (min-width: 760px) {
        margin-bottom: 20%;
    }
`

export const EmptyContainer = styled.div`
    margin-top: 50px;
`
export const EmptyInfoContainer = styled.div`
    width: 100%;
    align-content: center;
    align-items: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    // height: 100vh;
    margin-top: 10%;
`
export const EmptyImage = styled.img`
    width: 100px;
    height: 100px;
`

export const EmptyInfo = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 16px 0px;
`
export const EmptySubInfo = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;

    color: rgba(255, 255, 255, 0.8);

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 16px 0px;
`
