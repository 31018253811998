import { ColumnFlex } from '@/themes/genericStyles'
import styled from 'styled-components'

export const Container = styled.div`
    ${ColumnFlex}
    overflow-y: auto;
    width: 100%;
    padding: 10px 20px;
`
export const SearchContentContainer = styled.div`
    ${ColumnFlex}
    // overflow-y: auto;
    width: 100%;
    padding: 10px 0;
`

export const SearchBarContainer = styled.div`
    position: relative;
`
export const SearchBar = styled.input`
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    border: 0 none;
    font-size: 16px;
    line-height: normal;
    padding: 12px 16px;
    padding-left: ${({ focused }) => (focused ? '30px' : '16px')};
    border-radius: 30px;
    outline: none;
    ::placeholder {
        color: #dedede;
        opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder {
        color: #dedede;
    }
    ::-ms-input-placeholder {
        color: #dedede;
    }
`
export const SearchIconContainer = styled.div`
    position: absolute;
    top: 8px;
    right: 16px;
`
export const BackButton = styled.img`
    position: absolute;
    top: 12px;
    left: 10px;
    cursor: pointer;
`
export const ClearButton = styled.img`
    background-color: #707070;
    border-radius: 50%;
    padding: 4px;
    height: 20px;
    width: 20px;
    cursor: pointer;
`
