const Add = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <circle cx="8" cy="8.5" r="8" fill="white" />
            <path
                d="M4.94379 8.77822C4.79046 8.77822 4.66602 8.65377 4.66602 8.50044C4.66602 8.3471 4.79046 8.22266 4.94379 8.22266H11.0549C11.2082 8.22266 11.3327 8.3471 11.3327 8.50044C11.3327 8.65377 11.2082 8.77822 11.0549 8.77822H4.94379Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.45305 8.30359C5.32527 8.30359 5.22157 8.39144 5.22157 8.49967C5.22157 8.60791 5.32527 8.69576 5.45305 8.69576H10.5456C10.6734 8.69576 10.7771 8.60791 10.7771 8.49967C10.7771 8.39144 10.6734 8.30359 10.5456 8.30359H5.45305ZM5.45305 9.16634C5.01844 9.16634 4.66602 8.8678 4.66602 8.49967C4.66602 8.13155 5.01844 7.83301 5.45305 7.83301H10.5456C10.9803 7.83301 11.3327 8.13155 11.3327 8.49967C11.3327 8.8678 10.9803 9.16634 10.5456 9.16634H5.45305Z"
                fill="black"
            />
            <path
                d="M8.27724 11.5554C8.27724 11.7087 8.1528 11.8332 7.99946 11.8332C7.84613 11.8332 7.72168 11.7087 7.72168 11.5554V5.44428C7.72168 5.29095 7.84613 5.1665 7.99946 5.1665C8.1528 5.1665 8.27724 5.29095 8.27724 5.44428V11.5554Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.80359 11.0461C7.80359 11.1739 7.89144 11.2776 7.99967 11.2776C8.10791 11.2776 8.19576 11.1739 8.19576 11.0461V5.95354C8.19576 5.82576 8.10791 5.72206 7.99967 5.72206C7.89144 5.72206 7.80359 5.82576 7.80359 5.95354V11.0461ZM8.66634 11.0461C8.66634 11.4807 8.3678 11.8332 7.99967 11.8332C7.63155 11.8332 7.33301 11.4807 7.33301 11.0461V5.95354C7.33301 5.51893 7.63155 5.1665 7.99967 5.1665C8.3678 5.1665 8.66634 5.51893 8.66634 5.95354V11.0461Z"
                fill="black"
            />
        </svg>
    )
}
export default Add
