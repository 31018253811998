import React from 'react'

const Privacy = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.5 5.875H11.3738V3.30531C11.3738 1.48275 9.85981 0 7.99884 0C6.13788 0 4.62384 1.48275 4.62384 3.30531V5.875H3.5C2.46613 5.875 1.625 6.71613 1.625 7.75V14.125C1.625 15.1589 2.46613 16 3.5 16H12.5C13.5339 16 14.375 15.1589 14.375 14.125V7.75C14.375 6.71613 13.5339 5.875 12.5 5.875ZM5.87384 3.30531C5.87384 2.172 6.82713 1.25 7.99884 1.25C9.17056 1.25 10.1238 2.172 10.1238 3.30531V5.875H5.87384V3.30531ZM13.125 14.125C13.125 14.4696 12.8446 14.75 12.5 14.75H3.5C3.15538 14.75 2.875 14.4696 2.875 14.125V7.75C2.875 7.40538 3.15538 7.125 3.5 7.125H12.5C12.8446 7.125 13.125 7.40538 13.125 7.75V14.125Z"
                fill="white"
            />
            <path
                d="M8 8.9375C7.36141 8.9375 6.84375 9.45516 6.84375 10.0938C6.84375 10.5015 7.055 10.8597 7.37384 11.0656V12.4375C7.37384 12.7827 7.65366 13.0625 7.99884 13.0625C8.344 13.0625 8.62384 12.7827 8.62384 12.4375V11.067C8.94397 10.8614 9.15625 10.5025 9.15625 10.0938C9.15625 9.45516 8.63859 8.9375 8 8.9375Z"
                fill="white"
            />
        </svg>
    )
}
export default Privacy
