import { AmplitudeEventTracking } from '@/helpers/amplitudeEventTracking'
import { EventTracking } from '@/helpers/eventTracking'
import { getDevice } from '@/helpers/getDeviceInfo'

export const EVENT_NAMES = {
    TIME_TO_PLAY: 'playback latency',
    MARK_AS_PLAYED: 'Mark as played',
    ADD_TO_QUEUE: 'Add to queue',
    SHARE: 'Share',
    DOWNLOAD: 'Download',
    SEARCH_PAGE_OPENED: 'search page opened',
    COLLECTION_PAGE_VIEWED: 'collection page viewed',
    PROFILE_PAGE_VIEWED: 'profile page viewed',
}

// Handle time-to-play event
export const TRIGGER_TIME_TO_PLAY = (timeToPlay, content) => {
    const firebaseParams = {
        vertical: content?.catalog?.type,
        catalogId: content?.catalogId,
        catalogTitle: content?.catalog?.title,
        catalogSource: content?.catalog?.source,
        contentId: content?.id,
        contentTitle: content?.title,
        networkType:
            (navigator && navigator?.connection?.effectiveType) || '4g',
        bufferTime: timeToPlay > 0 ? timeToPlay : 0,
    }

    const amplitudeParams = {
        vertical: content?.catalog?.type,
        'catalog id': content?.catalogId,
        'catalog title': content?.catalog?.title,
        'catalog source': content?.catalog?.source,
        'content id': content?.id,
        'content title': content?.title,
        'network type':
            (navigator && navigator?.connection?.effectiveType) || '4g',
        'buffer time': timeToPlay > 0 ? timeToPlay : 0,
    }

    fire(EVENT_NAMES.TIME_TO_PLAY, firebaseParams, amplitudeParams)
}

// Handle mark-as-played event
export const TRIGGER_MARK_AS_PLAYED = (content) => {
    const amplitudeParams = {
        vertical: content?.catalog?.type,
        'catalog id': content?.catalogId,
        'catalog title': content?.catalog?.title,
        'catalog source': content?.catalog?.source,
        'content id': content?.id,
        'content title': content?.title,
        'device category': getDevice() || 'desktop',
    }
    fire(EVENT_NAMES.MARK_AS_PLAYED, null, amplitudeParams)
}

// Handle add-to-queue event
export const TRIGGER_ADD_TO_QUEUE = (content) => {
    const amplitudeParams = {
        vertical: content?.catalog?.type,
        'catalog id': content?.catalogId,
        'catalog title': content?.catalog?.title,
        'catalog source': content?.catalog?.source,
        'content id': content?.id,
        'content title': content?.title,
        'device category': getDevice() || 'desktop',
    }
    fire(EVENT_NAMES.ADD_TO_QUEUE, null, amplitudeParams)
}

// Handle share event
export const TRIGGER_SHARE = (content) => {
    const amplitudeParams = {
        vertical: content?.catalog?.type,
        'catalog id': content?.catalogId,
        'catalog title': content?.catalog?.title,
        'catalog source': content?.catalog?.source,
        'content id': content?.id,
        'content title': content?.title,
        'device category': getDevice() || 'desktop',
    }
    fire(EVENT_NAMES.SHARE, null, amplitudeParams)
}

// Handle download event
export const TRIGGER_DOWNLOAD = (content) => {
    const amplitudeParams = {
        vertical: content?.catalog?.type,
        'catalog id': content?.catalogId,
        'catalog title': content?.catalog?.title,
        'catalog source': content?.catalog?.source,
        'content id': content?.id,
        'content title': content?.title,
        'device category': getDevice() || 'desktop',
    }
    fire(EVENT_NAMES.DOWNLOAD, null, amplitudeParams)
}

// Handle search-page-opened event
export const TRIGGER_SEARCH_PAGE_OPENED = () => {
    fire(EVENT_NAMES.SEARCH_PAGE_OPENED, null, {
        'device category': getDevice() || 'desktop',
    })
}

// Handle collection-page-viewed event
export const TRIGGER_COLLECTION_PAGE_VIEWED = () => {
    fire(EVENT_NAMES.COLLECTION_PAGE_VIEWED, null, {
        'device category': getDevice() || 'desktop',
    })
}

// Handle profile-page-viewed event
export const TRIGGER_PROFILE_PAGE_VIEWED = () => {
    fire(EVENT_NAMES.PROFILE_PAGE_VIEWED, null, {
        'device category': getDevice() || 'desktop',
    })
}

const fire = (eventName, firebaseParams, amplitudeParams) => {
    firebaseParams && EventTracking(eventName, firebaseParams)
    amplitudeParams && AmplitudeEventTracking(eventName, amplitudeParams)
}
