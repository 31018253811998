const Coin = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <path
                d="M13.7617 8.51718C13.7617 11.6993 11.182 14.279 7.99985 14.279C4.81769 14.279 2.23804 11.6993 2.23804 8.51718C2.23804 5.33502 4.81769 2.75537 7.99985 2.75537C11.182 2.75537 13.7617 5.33502 13.7617 8.51718Z"
                fill="#DE9C37"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.99985 14.2785C11.182 14.2785 13.7617 11.6988 13.7617 8.51668C13.7617 7.10687 13.2553 5.81532 12.4146 4.81396L3.5874 12.2221C4.64434 13.4794 6.22878 14.2785 7.99985 14.2785Z"
                fill="#DC8F1B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.0001 15.3737C11.6914 15.3737 14.6838 12.3814 14.6838 8.69005C14.6838 4.99874 11.6914 2.00635 8.0001 2.00635C4.3088 2.00635 1.31641 4.99874 1.31641 8.69005C1.31641 12.3814 4.3088 15.3737 8.0001 15.3737ZM8.0001 14.4519C11.1823 14.4519 13.7619 11.8722 13.7619 8.69005C13.7619 5.50789 11.1823 2.92824 8.0001 2.92824C4.81795 2.92824 2.2383 5.50789 2.2383 8.69005C2.2383 11.8722 4.81795 14.4519 8.0001 14.4519Z"
                fill="#925D0C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.0001 15.2009C11.6914 15.2009 14.6838 12.2085 14.6838 8.5172C14.6838 4.82589 11.6914 1.8335 8.0001 1.8335C4.3088 1.8335 1.31641 4.82589 1.31641 8.5172C1.31641 12.2085 4.3088 15.2009 8.0001 15.2009ZM8.0001 14.279C11.1823 14.279 13.7619 11.6994 13.7619 8.5172C13.7619 5.33504 11.1823 2.75539 8.0001 2.75539C4.81795 2.75539 2.2383 5.33504 2.2383 8.5172C2.2383 11.6994 4.81795 14.279 8.0001 14.279Z"
                fill="#FFD15C"
            />
            <path
                d="M9.36892 5.48367C9.45012 5.44393 9.56704 5.46356 9.61339 5.53384C9.77933 5.7636 9.9423 5.99529 10.0834 6.23644C10.5724 7.06458 10.8033 7.9933 10.7607 8.91233C10.7191 9.74847 10.4531 10.5773 9.97685 11.3177C9.8741 11.4811 9.75866 11.6386 9.64823 11.7986C9.61487 11.8437 9.58003 11.8979 9.51124 11.9098C9.4389 11.9314 9.36745 11.897 9.30544 11.8698C8.53867 11.5046 7.76895 11.1428 7.00217 10.7773C6.92777 10.7426 6.84805 10.6731 6.88082 10.5975C6.92659 10.5092 6.99479 10.4305 7.05089 10.3469C7.32134 9.97362 7.52034 9.56161 7.59681 9.13069C7.6783 8.65494 7.62132 8.16392 7.43679 7.70805C7.4235 7.6603 7.36002 7.61716 7.30008 7.64043C7.20531 7.67169 7.12146 7.72113 7.0317 7.76088C6.96231 7.79578 6.88141 7.82244 6.8324 7.87867C6.8265 7.92302 6.84776 7.96567 6.85986 8.00833C7.0255 8.49499 7.01694 9.02406 6.81233 9.50175C6.69895 9.78167 6.5156 10.0369 6.33283 10.2889C6.28323 10.3672 6.15332 10.3801 6.06858 10.333C5.83622 10.2247 5.60504 10.1139 5.37267 10.0051C5.30211 9.97192 5.22623 9.91618 5.23508 9.84299C5.35437 9.6125 5.55632 9.41256 5.64371 9.16923C5.7875 8.82532 5.76536 8.43852 5.595 8.10382C5.50583 7.91962 5.36588 7.757 5.25486 7.58226C5.20999 7.52434 5.24719 7.45163 5.30683 7.41212C5.37976 7.36414 5.46568 7.33215 5.54539 7.29288C6.26345 6.95358 6.98032 6.61307 7.69779 6.27304C7.77396 6.23087 7.89236 6.2236 7.95289 6.28927C8.47992 6.96521 8.85016 7.74464 8.86847 8.55485C8.88884 9.06453 8.79141 9.57615 8.58443 10.0567C8.55609 10.1285 8.51239 10.1971 8.49793 10.2722C8.51092 10.3216 8.57203 10.3452 8.61928 10.3701C8.7297 10.4198 8.83481 10.4775 8.94907 10.5213C8.99513 10.5453 9.0592 10.5269 9.08253 10.4879C9.70581 9.29113 9.66359 7.87818 8.98391 6.70347C8.86168 6.48704 8.71287 6.282 8.56731 6.076C8.54162 6.03407 8.50088 5.98899 8.52066 5.93955C8.54635 5.86636 8.63256 5.82807 8.70756 5.79317C8.92929 5.6921 9.14601 5.58353 9.36892 5.48367Z"
                fill="#925D0C"
            />
            <path
                d="M9.36892 5.31033C9.45012 5.27059 9.56704 5.29022 9.61339 5.3605C9.77933 5.59026 9.9423 5.82195 10.0834 6.0631C10.5724 6.89124 10.8033 7.81996 10.7607 8.73899C10.7191 9.57513 10.4531 10.404 9.97685 11.1444C9.8741 11.3078 9.75866 11.4653 9.64823 11.6252C9.61487 11.6703 9.58003 11.7246 9.51124 11.7365C9.4389 11.7581 9.36745 11.7236 9.30544 11.6965C8.53867 11.3313 7.76895 10.9694 7.00217 10.6039C6.92777 10.5693 6.84805 10.4997 6.88082 10.4241C6.92659 10.3359 6.99479 10.2571 7.05089 10.1735C7.32134 9.80028 7.52034 9.38827 7.59681 8.95735C7.6783 8.4816 7.62132 7.99058 7.43679 7.53471C7.4235 7.48696 7.36002 7.44382 7.30008 7.46709C7.20531 7.49835 7.12146 7.54779 7.0317 7.58754C6.96231 7.62244 6.88141 7.6491 6.8324 7.70533C6.8265 7.74968 6.84776 7.79233 6.85986 7.83499C7.0255 8.32165 7.01694 8.85072 6.81233 9.3284C6.69895 9.60833 6.5156 9.86353 6.33283 10.1156C6.28323 10.1939 6.15332 10.2067 6.06858 10.1597C5.83622 10.0514 5.60504 9.9406 5.37267 9.83178C5.30211 9.79858 5.22623 9.74284 5.23508 9.66965C5.35437 9.43916 5.55632 9.23922 5.64371 8.99589C5.7875 8.65198 5.76536 8.26518 5.595 7.93048C5.50583 7.74628 5.36588 7.58366 5.25486 7.40892C5.20999 7.351 5.24719 7.27829 5.30683 7.23878C5.37976 7.1908 5.46568 7.15881 5.54539 7.11954C6.26345 6.78024 6.98032 6.43973 7.69779 6.0997C7.77396 6.05753 7.89236 6.05026 7.95289 6.11594C8.47992 6.79187 8.85016 7.5713 8.86847 8.38151C8.88884 8.89119 8.79141 9.40281 8.58443 9.88341C8.55609 9.95515 8.51239 10.0237 8.49793 10.0989C8.51092 10.1483 8.57203 10.1718 8.61928 10.1968C8.7297 10.2465 8.83481 10.3041 8.94907 10.348C8.99513 10.372 9.0592 10.3536 9.08253 10.3146C9.70581 9.11779 9.66359 7.70484 8.98391 6.53013C8.86168 6.3137 8.71287 6.10866 8.56731 5.90266C8.54162 5.86073 8.50088 5.81565 8.52066 5.76621C8.54635 5.69302 8.63256 5.65473 8.70756 5.61983C8.92929 5.51876 9.14601 5.41019 9.36892 5.31033Z"
                fill="#FFD15C"
            />
        </svg>
    )
}
export default Coin
