import SearchIcon from '@/components/Footer/BottomNavigation/icons/SearchIcon'
// import Search from '@/components/Pages/Search'
// import SearchContent from '@/components/Pages/Search/SearchContent'
import {
    Container,
    SearchBar,
    SearchBarContainer,
    SearchIconContainer,
} from '@/components/Pages/Search/styles'
// import * as searchSelectors from '@/store/selectors/search'
import { updateFocus } from '@/store/actions/search'
// import SkeletonContainer from '@/components/Home/VerticalRadioContent/SkeletonContainer'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

export const Search = () => {
    const dispatch = useDispatch()
    const router = useRouter()

    useEffect(() => {
        dispatch(updateFocus(false))
    }, [dispatch])

    dispatch(updateFocus(true))

    return (
        <Container
            style={{
                paddingLeft: '5px',
            }}
        >
            <SearchBarContainer>
                <SearchBar
                    // style={{
                    //     borderRadius: '30px',
                    //     background: 'rgba(255, 255, 255, 0.10)',
                    // }}
                    placeholder="Cari podcast, radio atau audiobook"
                    onFocus={() => {
                        router.push('/search').then(() => {
                            dispatch(updateFocus(true))
                        })
                    }}
                />
                <SearchIconContainer>
                    <SearchIcon color="#dedede" />
                </SearchIconContainer>
            </SearchBarContainer>
        </Container>
    )
}
