import React from 'react'

const UserProfile = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2113_17104)">
                <path
                    d="M7.99863 16H1.99595C1.06794 16 0.472608 15.6706 0.18194 15.1433C-0.0293939 14.7586 -0.0540605 14.3019 0.0946066 13.8159C0.214607 13.4193 0.457274 12.9953 0.808608 12.5773C1.99728 11.1639 4.58195 9.68726 7.99863 9.68726C11.4153 9.68726 14 11.1639 15.1887 12.5773C15.54 12.9953 15.782 13.4193 15.9027 13.8159C16.0507 14.3019 16.0267 14.7586 15.8153 15.1433C15.5247 15.6706 14.9293 16 14.0013 16H7.99863ZM1.99595 14.6666H14.0013C14.386 14.6666 14.5926 14.6033 14.6487 14.5026C14.6806 14.4439 14.674 14.3393 14.632 14.2013C14.5626 13.9733 14.4073 13.7099 14.1733 13.4319C13.1813 12.2526 10.9753 11.0206 7.99863 11.0206C5.02195 11.0206 2.81595 12.2526 1.82395 13.4319C1.58994 13.7099 1.43461 13.9733 1.36528 14.2013C1.32328 14.3386 1.31661 14.4439 1.34861 14.5026C1.40461 14.6033 1.61061 14.6666 1.99595 14.6666Z"
                    fill="white"
                />
                <path
                    d="M7.99886 0C9.20087 0 10.2895 0.487336 11.0769 1.27534C11.8649 2.06268 12.3522 3.15135 12.3522 4.35335C12.3522 5.55536 11.8649 6.64403 11.0769 7.43137C10.2895 8.21937 9.20087 8.70671 7.99886 8.70671C6.79686 8.70671 5.70818 8.21937 4.92085 7.43137C4.13284 6.64403 3.64551 5.55536 3.64551 4.35335C3.64551 3.15135 4.13284 2.06268 4.92085 1.27534C5.70818 0.487336 6.79686 0 7.99886 0ZM10.1342 2.21801C9.5882 1.67134 8.83287 1.33334 7.99886 1.33334C7.16486 1.33334 6.40952 1.67134 5.86352 2.21801C5.31685 2.76401 4.97885 3.51935 4.97885 4.35335C4.97885 5.18736 5.31685 5.9427 5.86352 6.4887C6.40952 7.03537 7.16486 7.37337 7.99886 7.37337C8.83287 7.37337 9.5882 7.03537 10.1342 6.4887C10.6809 5.9427 11.0189 5.18736 11.0189 4.35335C11.0189 3.51935 10.6809 2.76401 10.1342 2.21801Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_2113_17104">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default UserProfile
