import {
    INITIALIZE_USER_PROFILE_PAGE,
    RESET_USER_PROFILE_PAGE,
    FETCH_USER_PROFILE_STATUS,
    UPDATE_PROFILE_TAB,
    SET_PROFILE_DETAILS,
    SET_FOLLOWERS,
    SET_FOLLOWING,
    RESET_FOLLOWERS_PAGE,
    RESET_FOLLOWING_PAGE,
    FETCH_FOLLOWERS_STATUS,
    FETCH_FOLLOWING_STATUS,
    SET_SHOW_REGISTRATION_PROMPT,
    SET_USER_WALLET,
    SET_USER_WALLET_TRANSACTION,
    SET_USER_SUBSCRIPTION,
} from '@/store/types/user'

export const initializeUserProfilePage = (payload) => ({
    type: INITIALIZE_USER_PROFILE_PAGE,
    payload,
})

export const resetUserProfilePage = () => ({
    type: RESET_USER_PROFILE_PAGE,
})

export const fetchUserProfileStatus = (payload) => ({
    type: FETCH_USER_PROFILE_STATUS,
    payload,
})

export const updateProfileTab = (payload) => ({
    type: UPDATE_PROFILE_TAB,
    payload,
})

export const setProfileDetails = (payload) => ({
    type: SET_PROFILE_DETAILS,
    payload,
})

export const setFollowers = (payload) => ({
    type: SET_FOLLOWERS,
    payload,
})

export const setFollowing = (payload) => ({
    type: SET_FOLLOWING,
    payload,
})

export const resetFollowerPage = () => ({
    type: RESET_FOLLOWERS_PAGE,
})

export const fetchFollowerStatus = (payload) => ({
    type: FETCH_FOLLOWERS_STATUS,
    payload,
})

export const resetFollowingPage = () => ({
    type: RESET_FOLLOWING_PAGE,
})

export const fetchFollowingStatus = (payload) => ({
    type: FETCH_FOLLOWING_STATUS,
    payload,
})

export const setShowRegistrationPrompt = (payload) => ({
    type: SET_SHOW_REGISTRATION_PROMPT,
    payload,
})

export const setUserWallet = (payload) => ({
    type: SET_USER_WALLET,
    payload,
})

export const setUserWalletTranscation = (payload) => ({
    type: SET_USER_WALLET_TRANSACTION,
    payload,
})

export const setUserSubscription = (payload) => ({
    type: SET_USER_SUBSCRIPTION,
    payload,
})
