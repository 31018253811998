import React from 'react'

const Logout = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2113_17147)">
                <path
                    d="M9.33366 1.33333C8.96499 1.33333 8.66699 1.03467 8.66699 0.666667C8.66699 0.298667 8.96499 0 9.33366 0H13.3336C14.0656 0 14.7316 0.3 15.2163 0.784L15.2176 0.782667C15.7003 1.266 16.0003 1.93267 16.0003 2.66667V13.3333C16.0003 14.0673 15.7003 14.734 15.2176 15.2173C15.2036 15.2307 15.1896 15.244 15.175 15.256C14.6943 15.7167 14.045 16 13.3336 16H9.33366C8.96499 16 8.66699 15.7013 8.66699 15.3333C8.66699 14.9653 8.96499 14.6667 9.33366 14.6667H13.3336C13.6876 14.6667 14.009 14.5287 14.247 14.304L14.275 14.2747C14.517 14.0327 14.667 13.6993 14.667 13.3333V2.66667C14.667 2.30067 14.517 1.96733 14.275 1.72533L14.2763 1.724L14.275 1.72267C14.0343 1.48267 13.7016 1.33333 13.3336 1.33333H9.33366Z"
                    fill="white"
                />
                <path
                    d="M12 8.66706C12.368 8.66706 12.6667 8.36839 12.6667 8.00039C12.6667 7.63239 12.368 7.33373 12 7.33373H2.276L4.516 5.09372C4.77667 4.83372 4.77667 4.41106 4.516 4.15105C4.256 3.89105 3.834 3.89105 3.57333 4.15105L0.202 7.52239C0.0773332 7.64373 0 7.81306 0 8.00039C0 8.18773 0.0773332 8.35706 0.202 8.47839L3.57333 11.8497C3.834 12.1097 4.256 12.1097 4.516 11.8497C4.77667 11.5897 4.77667 11.1671 4.516 10.9071L2.276 8.66706H12Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_2113_17147">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default Logout
