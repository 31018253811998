const Diamond = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <g clipPath="url(#clip0_1_47948)">
                <path
                    d="M11.259 6.17334L7.99976 14.4996L15.3332 6.17334H11.259Z"
                    fill="#43C3FF"
                />
                <path
                    d="M8.0005 6.17334H4.74121L8.0005 14.4996L11.2598 6.17334H8.0005Z"
                    fill="#23A8E7"
                />
                <path
                    d="M0.666748 6.17334L8.00016 14.4996L4.74087 6.17334H0.666748Z"
                    fill="#087ABE"
                />
                <path
                    d="M15.3327 6.17336L12.0453 2.5L11.2585 6.17336H15.3327Z"
                    fill="#A7E3FF"
                />
                <path
                    d="M12.0458 2.5H7.99976L11.259 6.17336L12.0458 2.5Z"
                    fill="#23A8E7"
                />
                <path
                    d="M8.0005 6.17336H11.2598L8.0005 2.5L4.74121 6.17336H8.0005Z"
                    fill="#43C3FF"
                />
                <path
                    d="M7.99988 2.5H3.95386L4.74059 6.17336L7.99988 2.5Z"
                    fill="#23A8E7"
                />
                <path
                    d="M3.95414 2.5L0.666748 6.17336H4.74087L3.95414 2.5Z"
                    fill="#43C3FF"
                />
            </g>
            <defs>
                <clipPath id="clip0_1_47948">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
export default Diamond
