import React from 'react'

const Support = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2113_17138)">
                <path
                    d="M8 0C10.2092 0 12.2092 0.895385 13.6566 2.34338C15.1046 3.79077 16 5.79077 16 8C16 10.2092 15.1046 12.2092 13.6566 13.6566C12.2092 15.1046 10.2092 16 8 16C5.79077 16 3.79077 15.1046 2.34338 13.6566C0.895385 12.2092 0 10.2092 0 8C0 5.79077 0.895385 3.79077 2.34338 2.34338C3.79077 0.895385 5.79077 0 8 0ZM12.7865 3.21354C11.5618 1.98831 9.86954 1.23077 8 1.23077C6.13046 1.23077 4.43815 1.98831 3.21354 3.21354C1.98831 4.43815 1.23077 6.13046 1.23077 8C1.23077 9.86954 1.98831 11.5618 3.21354 12.7865C4.43815 14.0117 6.13046 14.7692 8 14.7692C9.86954 14.7692 11.5618 14.0117 12.7865 12.7865C14.0117 11.5618 14.7692 9.86954 14.7692 8C14.7692 6.13046 14.0117 4.43815 12.7865 3.21354Z"
                    fill="white"
                />
                <path
                    d="M6.3421 6.18647C6.3421 6.52616 6.06702 6.80185 5.72671 6.80185C5.38702 6.80185 5.11133 6.52616 5.11133 6.18647C5.11133 5.38893 5.43502 4.66647 5.95748 4.14401C6.48056 3.62154 7.20241 3.29785 7.99994 3.29785C8.79748 3.29785 9.51933 3.62154 10.0424 4.14401C10.5649 4.66647 10.8886 5.38893 10.8886 6.18647C10.8886 7.70524 9.96856 8.31262 9.19933 8.8197L9.16487 8.84124C8.86579 9.03878 8.61533 9.20801 8.61533 9.31816V10.0277C8.61533 10.368 8.33964 10.6431 7.99994 10.6431C7.66025 10.6431 7.38456 10.368 7.38456 10.0277V9.31816C7.38456 8.56185 7.89164 8.21355 8.48979 7.81847L8.52179 7.7957C9.03871 7.45478 9.65779 7.04616 9.65779 6.18647C9.65779 5.72862 9.47194 5.31385 9.17225 5.01416C8.87256 4.71447 8.45779 4.52862 7.99994 4.52862C7.5421 4.52862 7.12733 4.71447 6.82764 5.01416C6.52794 5.31385 6.3421 5.72862 6.3421 6.18647Z"
                    fill="white"
                />
                <path
                    d="M7.38477 11.9408C7.38477 11.6011 7.66046 11.3254 8.00016 11.3254C8.33986 11.3254 8.61555 11.6011 8.61555 11.9408V12.0867C8.61555 12.4264 8.33986 12.7021 8.00016 12.7021C7.66046 12.7021 7.38477 12.4264 7.38477 12.0867V11.9408Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_2113_17138">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default Support
