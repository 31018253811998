export default function convertToIndonesianCurrency(number) {
    // Convert the number to a string and remove any commas or periods
    const numberString = String(number).replace(/[,.]/g, '')

    // Add thousand separators to the number string
    const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

    // Return the formatted currency string
    return `${formattedNumber}`
}
